import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import type { Revision } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getPropertyByPath } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { MessageTimestamp } from '../../../types/enums';
import type { MessageTimeFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * The message to display the post time for.
   */
  message: MessageTimeFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Displays the type of timestamp.
   */
  timestampType: MessageTimestamp;
  /**
   * Display Last Update text before date
   */
  useLastUpdateDateText?: boolean;
  /**
   * Whether to show preferred date display style or not.
   */
  usePreferredDateDisplayStyle?: boolean;
  /**
   * The fallback component to render in the instance that the time is not present.
   */
  fallback?: React.ComponentType<React.PropsWithChildren<unknown>>;
  /**
   * Whether to use absolute date or not.
   */
  useAbsoluteDate?: boolean;
  /**
   * Revision object related to the message. When passed, the component displays the revision object's edit time.
   */
  revision?: Revision;
}

/**
 * Message post time.
 * @author Adam Ayres, Dolan Halbrook
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MessageTime: React.FC<React.PropsWithChildren<Props>> = ({
  as: Component = 'span',
  className,
  message,
  timestampType,
  useLastUpdateDateText = false,
  usePreferredDateDisplayStyle = false,
  fallback: FallbackComponent = null,
  useAbsoluteDate = false,
  revision
}) => {
  const cx = useClassNameMapper();
  const { formatMessage } = useTranslation(EndUserComponent.MESSAGE_TIME);
  const {
    authUser: {
      profileSettings: { dateDisplayStyle }
    }
  } = useContext(AppContext);
  const {
    formatRelativeTime,
    formatAbsoluteDateTime,
    getUserPreferredDateFormat,
    formatAbsoluteDate
  } = useDateTime();
  const time = getPropertyByPath(revision ?? message, timestampType);

  const formattedTime = !useAbsoluteDate
    ? usePreferredDateDisplayStyle && dateDisplayStyle.value === 'false'
      ? getUserPreferredDateFormat(time)
      : formatRelativeTime(time)
    : formatAbsoluteDate(time);

  /**
   * We suppress hydration warnings due to a bug in NodeJs that causes formatted dates to
   * render in the format of:
   *
   * August 26, 2022, 2:30 PM
   *
   * Which does not match the spec and browsers that implement it correctly:
   *
   * August 26, 2022 at 2:30 PM
   *
   * This bug has been fixed in NodeJs 18.
   */
  return time ? (
    <Component className={cx(className)} data-testid="messageTime">
      <span title={formatAbsoluteDateTime(time)} role="none" suppressHydrationWarning={true}>
        {useLastUpdateDateText && timestampType === MessageTimestamp.LAST_PUBLISHED_TIME
          ? formatMessage(timestampType, { time: formattedTime })
          : formattedTime}
      </span>
    </Component>
  ) : FallbackComponent ? (
    <FallbackComponent />
  ) : null;
};

export default MessageTime;
